/* Thin */
@font-face {
	font-family: 'Proxima Nova';
	src: url('./FontTypeFiles/ProximaNova-Thin.otf') format('opentype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('./FontTypeFiles/ProximaNova-ThinItalic.otf') format('opentype');
	font-weight: 100;
	font-style: italic;
}

/* Light */
@font-face {
	font-family: 'Proxima Nova';
	src: url('./FontTypeFiles/ProximaNova-Light.otf') format('opentype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('./FontTypeFiles/ProximaNova-LightItalic.otf') format('opentype');
	font-weight: 300;
	font-style: italic;
}

/* Regular/Normal */
@font-face {
	font-family: 'Proxima Nova';
	src: url('./FontTypeFiles/ProximaNova-Regular.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('./FontTypeFiles/ProximaNova-RegularItalic.otf') format('opentype');
	font-weight: normal;
	font-style: italic;
}

/* Semibold */
@font-face {
	font-family: 'Proxima Nova';
	src: url('./FontTypeFiles/ProximaNova-Semibold.otf') format('opentype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('./FontTypeFiles/ProximaNova-SemiboldItalic.otf') format('opentype');
	font-weight: 600;
	font-style: italic;
}

/* Bold */
@font-face {
	font-family: 'Proxima Nova';
	src: url('./FontTypeFiles/ProximaNova-Bold.otf') format('opentype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('./FontTypeFiles/ProximaNova-BoldItalic.otf') format('opentype');
	font-weight: bold;
	font-style: italic;
}

/* ExtraBold */
@font-face {
	font-family: 'Proxima Nova';
	src: url('./FontTypeFiles/ProximaNova-Extrabold.otf') format('opentype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('./FontTypeFiles/ProximaNova-ExtraboldItalic.otf') format('opentype');
	font-weight: 800;
	font-style: italic;
}

/* Black */
@font-face {
	font-family: 'Proxima Nova';
	src: url('./FontTypeFiles/ProximaNova-Black.otf') format('opentype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('./FontTypeFiles/ProximaNova-BlackItalic.otf') format('opentype');
	font-weight: 900;
	font-style: italic;
}