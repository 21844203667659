.swiper-wrapper {
	width: 100%;
	max-width: 100%;
	max-height: 100vh;
	min-height: 0;
	min-width: 0;
}

.swiper-slide {
	width: auto;
	flex-shrink: 0;
	display: block;
	height: 100%;
	max-height: 100%;
}